
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  Col,
  Row,
  Tab,
  Tabs,
  Popup,
  Icon,
  Popover,
  Button,
  Image,
  List,
  Sticky,
} from "vant";
import SearchLocation from "@/views/Classify/SearchLocation.vue";
import Show from "@/views/Show/Show.vue";
import CategorySelector from "@/components/CategorySelector.vue";
import CalendarSelector from "@/components/CalendarSelector.vue";
import { classForm } from "@/shims-video";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popover.name]: Popover,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Image.name]: Image,
    [List.name]: List,
    [Sticky.name]: Sticky,
    Show,
    SearchLocation,
    CategorySelector,
    CalendarSelector,
  },
})
export default class ClassFication extends Mixins(Mixin) {
  public caterId: string | null = "";
  public classList: dictionary.ProductCategoryDto[] = [];
  public catelist: { code: string; name: string }[] = [];
  public choseTypeBtnText = "一月内";
  // 演出页面 默认 时间一个月内
  public searchform: any = {
    discountType: "",
    priceEnum: "",
    showType: "",
    startTimeStamp: new Date().getTime(),
    endTimeStamp: new Date().getTime() + 3600 * 1000 * 24 * 30,
    type: "month",
  };
  @Watch("$route")
  routeChange(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    setTimeout(() => {
      vm.caterId = vm.HomeDataModule.classFicationCategoryTabName;
    }, 500);
  }
  mounted(): void {
    console.log(123123123);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    // 无需准确定位
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    setTimeout(() => {
      vm.caterId = vm.HomeDataModule.classFicationCategoryTabName;
      console.log(11111, vm.caterId);
    }, 500);
    this.getClassList();
  }

  /**
   * 点击搜索栏
   */
  clickSearch(): void {
    monitorEvent("ItemList_ClickSearch", "点击搜索栏"); // 埋点：分类页，点击搜索栏
    this.$router.push("/search/show");
  }

  // 右侧综合、筛选
  // 切换频道
  changeTab(name: string, title: string): void {
    this.caterId = name;
    this.HomeDataModule.SET_classFicationCategoryTabName_PERSIST(name);
    monitorEvent("ItemList_ClickType", "点击某个分类", title); // 埋点：分类页，点击某个分类
  }

  // 返回主页
  returnHome(): void {
    this.$router.go(-1);
  }
  selectcalendar(form: {
    startTimeStamp: Date;
    endTimeStamp: Date;
    type: string;
  }): void {
    switch (form.type) {
      case "all":
        this.choseTypeBtnText = "全部时间";
        break;
      case "week":
        this.choseTypeBtnText = "一周内";
        break;
      case "month":
        this.choseTypeBtnText = "一月内";
        break;
      case "weekend":
        this.choseTypeBtnText = "本周末";
        break;
      case "appoint":
        // this.choseTypeBtnText = "指定时间";
        this.choseTypeBtnText = this.formDate(
          String(new Date(form.startTimeStamp).getTime())
        );
        break;
    }
    this.searchform.startTimeStamp = form.startTimeStamp;
    this.searchform.endTimeStamp = form.endTimeStamp;
    this.searchform.type = form.type;
    this.submitSelect(this.searchform);
  }
  // selectCategory(val: classForm): void {
  //   this.searchform.discountType = val.discountType;
  //   this.searchform.priceEnum = val.priceEnum;
  //   this.searchform.showType = val.showType;
  //   this.submitSelect(this.searchform);
  // }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    return `${y}-${m}-${d}`;
  }
  // 获取全部分类
  getClassList(): void {
    this.$api.dictionaryApi.performance.searchCategories(({ data }) => {
      this.classList = data;
    });
  }

  /**
   * 打开分类页中的右侧弹框
   */
  openCategorySelector(): void {
    let categorySelector = this.$refs["category-selector"] as CategorySelector;
    if (categorySelector) {
      categorySelector.open();
    }
  }
  // 打开选择日历
  openCalendarSelector(): void {
    let calendarSelector = this.$refs["calendar-selector"] as CalendarSelector;
    if (calendarSelector) {
      calendarSelector.open();
    }
  }

  /**
   * 右侧弹框中提交了筛选条件，调用演出列表中的方法根据查询表单查询数据
   */
  submitSelect(val: classForm): void {
    // 演出页面 默认筛选时间 不影响 渠道分销页面时间筛选
    this.searchform.discountType = val.discountType;
    this.searchform.priceEnum = val.priceEnum;
    this.searchform.showType = val.showType;
    let showList = this.$refs["show-list"] as Show;
    if (showList) {
      if (this.isChannelDistribution) {
        showList.queryData(val);
      } else {
        showList.queryData(this.searchform);
      }
    }
  }
}
