
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Search, Icon, Image } from "vant";

@Component({
  components: {
    Mixin,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [Image.name]: Image,
  },
})
export default class SearchLocation extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: () => {
      return false;
    },
  })
  hideGoBackButton!: boolean; // 隐藏返回按钮

  // @Prop({ default: false }) readonly white!: boolean;

  // get background(): string {
  //   return this.white ? "transparent" : "#f5f5f5";
  // }

  public clickSearch(): void {
    this.$emit("click-search");
  }

  public returnHome(): void {
    this.$router.go(-1);
  }
}
